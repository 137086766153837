import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current testimonial index
  const [direction, setDirection] = useState("right"); // Track the direction for animations
  const [isPaused, setIsPaused] = useState(false); // Track if auto-scroll is paused

  const data = [
    {
      author: "Rahul Sharma, Class 12 Student",
      text: "The Advanced Report gave me deep insights into my strengths and weaknesses. It helped me focus on the areas where I needed improvement. A must-have for anyone serious about their exam preparation!"
    },
    {
      author: "Sneha Gupta, Class 10 Student",
      text: "This report showed me exactly where I need to put more effort. It's like having a personal guide for my studies!"
    },
    {
      author: "Ravi Kumar, Class 11 Student",
      text: "I never knew how much difference an advanced analysis could make. It gave me a clear direction for my study plan!"
    }
  ];

  const handleRightArrowClick = () => {
    setDirection("right"); // Set direction to right
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length); // Move to the next testimonial
  };

  const handleLeftArrowClick = () => {
    setDirection("left"); // Set direction to left
    setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length); // Move to the previous testimonial
  };

  // Auto-scroll every 5 seconds if not paused
  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        handleRightArrowClick();
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [isPaused]);

  return (
    <div className="Rep_testimonialsCont">
      {/* Left Arrow */}
      <motion.div
        onClick={handleLeftArrowClick}
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
        style={{ cursor: "pointer", marginRight: "auto" }}
        whileHover={{ x: [-4, 4, -4], transition: { duration: 0.1 } }} // Increased shake effect
        whileTap={{ scale: 1.1 }} // Scale on click
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
          <path
            d="M9.11495 0.99833C9.32586 1.2093 9.44434 1.4954 9.44434 1.7937C9.44434 2.09201 9.32586 2.37811 9.11495 2.58908L3.5462 8.15783L9.11495 13.7266C9.31988 13.9388 9.43327 14.2229 9.43071 14.5179C9.42815 14.8129 9.30983 15.095 9.10125 15.3036C8.89266 15.5122 8.6105 15.6305 8.31553 15.6331C8.02056 15.6356 7.73638 15.5223 7.5242 15.3173L1.16008 8.9532C0.94917 8.74224 0.83069 8.45614 0.83069 8.15783C0.83069 7.85952 0.94917 7.57342 1.16008 7.36245L7.5242 0.99833C7.73517 0.787425 8.02127 0.668945 8.31958 0.668945C8.61789 0.668945 8.90398 0.787425 9.11495 0.99833Z"
            fill={direction === "left" ? "#003B8F" : "#A0A3BD"} // Change color based on state
          />
        </svg>
      </motion.div>

      {/* Testimonial Text */}
      <div
        className="Rep_testimonialTextCont"
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        <motion.div
          key={currentIndex}
          initial={{ x: direction === "left" ? 100 : -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: direction === "left" ? -100 : 100, opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.p className="Rep_testimonialText">{data[currentIndex].text}</motion.p>
          <motion.p className="Rep_testimonialBy">— {data[currentIndex].author}</motion.p>
        </motion.div>
      </div>

      {/* Right Arrow */}
      <motion.div
        onClick={handleRightArrowClick}
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
        style={{ cursor: "pointer", marginLeft: "auto" }}
        whileHover={{ x: [4, -4, 4], transition: { duration: 0.1 } }}
        whileTap={{ scale: 1.1 }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
          <path
            d="M5.95438 1.99784C5.74348 2.20881 5.625 2.49491 5.625 2.79322C5.625 3.09153 5.74348 3.37762 5.95438 3.58859L11.5231 9.15734L5.95438 14.7261C5.74946 14.9383 5.63606 15.2224 5.63863 15.5174C5.64119 15.8124 5.7595 16.0946 5.96809 16.3031C6.17667 16.5117 6.45884 16.63 6.75381 16.6326C7.04878 16.6352 7.33296 16.5218 7.54513 16.3168L13.9093 9.95272C14.1202 9.74175 14.2386 9.45565 14.2386 9.15734C14.2386 8.85903 14.1202 8.57294 13.9093 8.36197L7.54513 1.99784C7.33417 1.78694 7.04807 1.66846 6.74976 1.66846C6.45145 1.66846 6.16535 1.78694 5.95438 1.99784Z"
            fill={direction === "right" ? "#003B8F" : "#A0A3BD"}
          />
        </svg>
      </motion.div>
    </div>
  );
};

export default Testimonials;
