import persistStore from "redux-persist/es/persistStore";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { setupListeners } from "@reduxjs/toolkit/query";
import app from "./app/app.slice";
import { countryApi } from "./app/country.api";
import { authApi } from "./auth/auth.api";
import auth from "./auth/auth.slice";
import { otpApi } from "./auth/otp.api";
import { apiMutaion } from "./ApiMutaion";
import { omsMutation } from "./OmsMutation";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"]
};

const apiReducers = [authApi, apiMutaion, omsMutation, otpApi, countryApi];

const reducers = combineReducers({
  ...apiReducers.reduce((acc, item) => ({ ...acc, [item.reducerPath]: item.reducer }), {}),
  auth,
  app
});

const persistedReducer = persistReducer(persistConfig, reducers);

const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false
  }).concat(apiReducers.map((item) => item.middleware));

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);
