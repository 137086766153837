import React, { useState, useRef, useEffect } from "react";
import tooltipboday from "../../../assets/svg/emsat/tooltipboday.svg";
export const EMsatToolTip = ({ children, content, onClick }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleTooltipClick = (event) => {
    event.stopPropagation();
    setShowTooltip(true);

    // Invoke the onClick callback provided by the parent component
    if (onClick) {
      onClick(content);
    }
  };

  const handleMouseEnter = () => {
    if (!showTooltip) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="tooltip-container">
      <div
        className="tooltip-trigger"
        onClick={handleTooltipClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      {showTooltip && (
        <div ref={tooltipRef} className="EMRep_tooltipp  EMRep_tooltip">
          <img className="EMRep_tooltippimg" src={tooltipboday} alt="tooltipbody" />
        </div>
      )}
    </div>
  );
};
