import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG_VARS } from "../constants/configuration";

const { baseUrl, apiVersion } = CONFIG_VARS;

export const apiMutaion = createApi({
  reducerPath: "apiMutaion",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth?.tokens?.access_token;
      if (token) {
        headers.set("Authorization", `${token}`);
      }
      headers.set("product-id", "100");
      headers.set("tenant-id", "99999");
      return headers;
    }
  }),
  endpoints: (build) => ({
    examReport: build.mutation({
      query: (examId) => ({
        url: `${baseUrl["1"]}/${apiVersion}/exams/report?exam_id=${examId}`,
        method: "GET"
      })
    }),

    examDetails: build.mutation({
      query: (examId) => ({
        url: `${baseUrl["1"]}/${apiVersion}/exams/?exam_id=${examId}`,
        method: "GET"
      })
    }),

    events: build.mutation({
      query: () => ({
        url: `${baseUrl["1"]}/${apiVersion}/events`,
        method: "GET"
      })
    }),
    eventStatus: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/exams/status?exam_id=${data.examId}&event_id=${data.event_id}`,
        method: "POST"
      })
    }),
    examStatus: build.mutation({
      query: (examId) => ({
        url: `${baseUrl["1"]}/${apiVersion}/exams/status?exam_id=${examId}`,
        method: "GET"
      })
    }),
    advanceReport: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/exams/advanced-report?exam_id=${data.examId}&event=${data.event}`,
        method: "GET"
      })
    }),
    advanceReportLSQ: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/exams/advanced-report?exam_id=${data.examId}&event_id=${data.eventId}&event=${data.event}`,
        method: "POST"
      })
    }),
    mockTest: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/exams/get-mock-test`,
        method: "POST",
        body: data
      })
    }),
    webEngage: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/webengage/event`,
        method: "POST",
        body: data
      })
    }),
    deviceDetailsLSQ: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/lsq?exam_id=${data.examId}&event_id=${data.eventId}`,
        method: "POST",
        body: data.payload
      })
    })
  })
});

export const {
  useEventsMutation,
  useExamReportMutation,
  useExamDetailsMutation,
  useEventStatusMutation,
  useExamStatusMutation,
  useAdvanceReportMutation,
  useAdvanceReportLSQMutation,
  useMockTestMutation,
  useWebEngageMutation,
  useDeviceDetailsLSQMutation
} = apiMutaion;
