import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tabID: 1,
  modalState: {
    open: false,
    content: null,
    title: ""
  },
  failedPayment: { isLoader: false, isfailed: false, res: null }
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setActiveTab(state, { payload }) {
      state.tabID = payload;
    },
    setModalState(state, { payload }) {
      state.modalState = {
        ...initialState.modalState,
        ...payload,
        open: true
      };
    },
    closeModal(state) {
      state.modalState = initialState.modalState;
    },
    setFailedPayment: (state, action) => {
      const { isLoader, isFailed, res } = action.payload;
      state.failedPayment = { isLoader, isFailed, res };
    }
  },
  extraReducers: () => {}
});

export const { setActiveTab, setModalState, closeModal, setFailedPayment } = appSlice.actions;
export default appSlice.reducer;
