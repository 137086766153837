import React, { useState, useEffect } from "react";
import { EMsatToolTip } from "./EMsatTooltip";
import i from "../../../assets/svg/emsat/i.svg";

export const PaiChart = ({ totalMarks, scoredMarks, accuracy }) => {
  const radius = 71;
  const circumference = 2 * Math.PI * radius;
  const [offset, setOffset] = useState(circumference);

  const percentage = (scoredMarks / totalMarks) * 100;
  const newOffset = circumference - (percentage / 100) * circumference;

  useEffect(() => {
    setOffset(newOffset);
  }, [scoredMarks, newOffset]);

  return (
    <div className="EMRep_Sub_CirclerCont">
      <svg
        width="252"
        height="180"
        viewBox="0 0 152 153"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="EMRep_pai-chart-svg"
      >
        <circle cx="76" cy="76.5" r={radius} stroke="#D9DBE9" strokeWidth="10" fill="none" />
        <circle
          cx="76"
          cy="76.5"
          r={radius}
          stroke="#FBD323"
          strokeWidth="10"
          fill="none"
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          transform="rotate(-90 76 76.5)"
          style={{ transition: "stroke-dashoffset 1s ease" }}
        />
      </svg>
      <div className="EMRep_chart-label">
        <p>
          Score
          <br />
          <span>{scoredMarks}/</span>
          {totalMarks}
        </p>
      </div>
      <div className="EMRep_chart-labelCont">
        <p className="EMRep_chart-labelContPara ">
          Your Accuracy :<span>{accuracy}%</span>
        </p>{" "}
        <EMsatToolTip>
          <img className="EMRe_info" style={{ marginBottom: "1rem" }} src={i} alt="i" />
        </EMsatToolTip>
      </div>
    </div>
  );
};
