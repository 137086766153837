import React from "react";
import timer from "../../assets/svg/reportspage/gif/timerGIf.gif";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../store/auth/selectors";
import lock from "../../assets/svg/reportspage/lock.svg";
import paidTick from "../../assets/svg/reportspage/paidTick.svg";
import olympaidblur from "../../assets/svg/reportspage/olympaidblur.jpg";
import emsatblur from "../../assets/svg/reportspage/emsatblur.jpg";
import scoreblur from "../../assets/svg/reportspage/scoreblur.jpg";
import Testimonials from "./Testimonials";
import { getDeviceDetails } from "../../utils/getDeviceDetails.utils";
import { useWebEngageMutation } from "../../store/ApiMutaion";
import tick from "../../assets/svg/reportspage/gif/tick.gif";
export const Timer = ({ condition, timeLeft, openPopup, openpdf, discount, examType, examId }) => {
  const userInfo = useSelector(getUserInfo);

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    const m = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const s = (seconds % 60).toString().padStart(2, "0");
    return `${h}h:${m}m:${s}s`;
  };
  const blur = (examType) => {
    if (examType.includes("score")) {
      return scoreblur;
    } else if (examType.includes("olympiad")) {
      return olympaidblur;
    } else if (examType.includes("emsat")) {
      return emsatblur;
    } else {
      return scoreblur; // Optional: handle other cases if none of the above match
    }
  };
  const [webEngage] = useWebEngageMutation();

  const deviceDetails = getDeviceDetails();

  const view_sample_report_clicked = async () => {
    try {
      await webEngage({
        crn_id: `${userInfo?.crn_id}`,
        event_name: "view_sample_report_clicked",
        event_data: [
          { attribute: "button_name", value: `View Sample Report` },
          { attribute: "page_url", value: `/reports/${userInfo?.crn_id}/${examId}` },
          { attribute: "device category", value: `${deviceDetails?.device_category}` },
          {
            attribute: "grade",
            value: `${userInfo?.grade_id}`
          },
          {
            attribute: "amount_shown",
            value: `${Math.floor(discount?.totalAmountAfterDiscount)}`
          },
          { attribute: "Platform", value: "InMobius" },
          { attribute: "payment_link", value: `` }
        ]
      });
    } catch (error) {
      console.error("Error pushing WebEngage event:", error);
    }
  };
  const buy_button_clicked = async () => {
    try {
      await webEngage({
        crn_id: `${userInfo?.crn_id}`,
        event_name: "buy_button_clicked",
        event_data: [
          { attribute: "button_name", value: `Buy Report` },
          { attribute: "page_url", value: `/reports/${userInfo?.crn_id}/${examId}` },
          { attribute: "device category", value: `${deviceDetails?.device_category}` },
          {
            attribute: "grade",
            value: `${userInfo?.grade_id}`
          },
          { attribute: "Platform", value: "InMobius" },
          { attribute: "payment_link", value: `` }
        ]
      });
    } catch (error) {
      console.error("Error pushing WebEngage event:", error);
    }
  };

  return (
    <div>
      {condition === "timer" && (
        <div
          className="Rep_bluredreport-cont"
          style={{
            backgroundImage: `url(${blur(examType)})`,
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
        >
          <img className="Rep_paidTick" src={lock} alt="lock" />
          <h4>Unlock Detailed Report at just </h4>
          <p className="Rep_price">
            ₹{Math.floor(discount?.totalAmountAfterDiscount)}
            <br />
            <span className="Rep_discountPrice">₹{discount?.totalAmount}</span>
            <span className="Rep_discount">{Math.floor(discount?.percentageOrAmount)}% discount!</span>
          </p>
          <p className="Rep_timer-timecont">
            Limited Period Offer <br />
            Ends in <span className="Rep_timer-time">{formatTime(timeLeft)}</span>
          </p>
          <button
            onClick={() => {
              buy_button_clicked();
              openPopup();
            }}
            className="attempt-btn"
          >
            Buy Report at ₹{Math.floor(discount?.totalAmountAfterDiscount)}
          </button>
          <p
            onClick={() => {
              view_sample_report_clicked();
              openpdf();
            }}
            className="Rep_sampleReportCTA"
          >
            View Sample Report
          </p>
          <div style={{ width: "100%" }}>
            <Testimonials />
          </div>
        </div>
      )}
      {condition === "almost" && (
        <div
          className="Rep_bluredreport-cont"
          style={{
            backgroundImage: `url(${blur(examType)})`,
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
        >
          <img className="Rep_paidTick" src={paidTick} alt="lock" />
          <h4>Payment is Successful </h4>
          <p className="Rep_timer-timecont">
            We are generating a detailed report for you.
            <br /> it will be available shortly
            <br />
            <span className="Rep_Paidtimer-time">{formatTime(timeLeft)}</span>
          </p>
          <img className="Rep_sandtimer" src={timer} alt="timer" />
          <p onClick={openpdf} className="Rep_sampleReportCTA">
            View Sample Report
          </p>
          <div style={{ width: "100%" }}>
            <Testimonials />
          </div>{" "}
        </div>
      )}
      {condition === "Notyet" && (
        <div
          className="Rep_bluredreport-cont"
          style={{
            backgroundImage: `url(${blur(examType)})`,
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
        >
          <img className="Rep_paidTick" src={paidTick} alt="lock" />
          <h4>Payment is Successful </h4>
          <h1>Uh-uh!</h1>
          <p className="Rep_timer-des">
            Your payment was successful, but there’s a slight delay in generating your report. It will be ready soon!
          </p>
          <p className="Rep_timer-des">
            If you don’t see it in a few minutes, please call our support team at +91 87927 40465. We’re here to help!{" "}
          </p>
          <img className="Rep_sandtimer" src={timer} alt="timer" />
          <p className="Rep_timer-des">Thank you for your patience! </p>
          <p onClick={openpdf} className="Rep_sampleReportCTA">
            View Sample Report
          </p>
          <div style={{ width: "100%" }}>
            <Testimonials />
          </div>{" "}
        </div>
      )}

      {condition === "NoPymmenttimer" && (
        <div className="Rep_timer-cont">
          <h1>But Wait!</h1>
          <p className="Rep_timer-des">
            We are generating a detailed report for you.
            <br />
            <span>You can check back in</span>
          </p>
          <p className="Rep_timer-time">{formatTime(timeLeft)}</p>
          <img src={timer} alt="Timer" />
        </div>
      )}
      {condition === "NoPymmentalmost" && (
        <div className="Rep_timer-cont">
          <h1>Uh-uh!</h1>
          <p className="Rep_timer-des">
            Rest assured, your report is in the queue.
            <br />
            We will update you once it&apos;s ready
          </p>
          <img src={timer} alt="Timer" />
        </div>
      )}
      {condition === "NoPymmentready" && (
        <div className="Rep_timer-cont">
          <h1>Your detailed report is ready!</h1>
          <img src={tick} alt="Tick" />
          <button className="attempt-btn">View Detailed Report</button>
        </div>
      )}
    </div>
  );
};
