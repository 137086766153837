import { USER_TYPES } from "./auth.slice";

export const getIsAuthenticated = (state) => !!state?.auth?.user && !!state?.auth?.tokens?.access_token;
export const getIsActive = (state) => state?.auth?.userType !== USER_TYPES.SCHOOL || !!state?.auth?.user?.is_active;
export const getUserInfo = (state) => state?.auth?.user;
export const getMdm = (state) => state?.auth?.mdm;
export const isSchoolUserType = (state) => state?.auth?.userType === USER_TYPES.SCHOOL;
export const isStudentUserType = (state) => state?.auth?.userType === USER_TYPES.STUDENT;
export const getPlanInfo = (state) => state?.auth?.planInfo;
export const getSelectedEventsDetails = (state) => state.auth.selectedEventsDetails;
export const getToken = (state) => state.auth.tokens.access_token;
export const getRegistrationPayload = (state) => state.auth.registrationPayload;
export const getPaymentsDetails = (state) => state.auth.paymentsDetails;
