import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  useExamReportMutation,
  useExamDetailsMutation,
  useEventStatusMutation,
  useAdvanceReportMutation,
  useExamStatusMutation,
  useWebEngageMutation,
  useDeviceDetailsLSQMutation
} from "../store/ApiMutaion";
import loader from "../assets/gif/newLoader.gif";
import AdvanceReport from "./components/AdvanceReport";
import { useSelector } from "react-redux";
import { getSelectedEventsDetails, getToken } from "../store/auth/selectors";
import { format, parseISO } from "date-fns";
import { CONFIG_VARS } from "../constants/configuration";
import BasicReport from "./components/BasicReport";
import { Timer } from "./components/Timer";
import PackageReviwPopup from "./components/modal/PackageReviwPopup";
import SampleReportPopup from "./components/modal/SampleReportPopup";
import { createDeviceDetailsPayloadLSQ } from "../utils/getDeviceDetails.utils";
import useOMSQueries from "./ReportsOMSQueries";

export const Reports = () => {
  const { examId } = useParams();
  const history = useHistory();

  const [reportData, setReportData] = useState([]);
  const [progressordata, setProgressordata] = useState({});
  const [examDetails, setExamDetails] = useState([]);
  const [condition, setCondition] = useState("timer");
  const [timeLeft, setTimeLeft] = useState(7200);
  const [reportLoader, setReportLoader] = useState(true);
  const [advanceReport, setAdvanceReport] = useState([]);
  const [advanced, setAdvanced] = useState(false);
  const [fetchExamReport] = useExamReportMutation();
  const [fetchExamDetails] = useExamDetailsMutation();
  const [updateStatus] = useEventStatusMutation();
  const [fetchAdvancereport] = useAdvanceReportMutation();
  const [webEngage] = useWebEngageMutation();
  const [sendDeviceDetails] = useDeviceDetailsLSQMutation();
  const [formetDate, setFormetData] = useState();
  const [hasFetched, setHasFetched] = useState(false);
  const examdetailes = useSelector(getSelectedEventsDetails);
  const [fetchExamStatus] = useExamStatusMutation();
  const currentExam = examdetailes?.title?.toLowerCase().replace(/[\s\W]+/g, "");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPdfPop, setIsPdfPop] = useState(false);
  const [discount, setDiscount] = useState();
  const { handlePaymentFlow } = useOMSQueries();
  const isPaidReport = examdetailes?.is_paid_report;
  const openPopup = () => {
    setIsPopupOpen(true);
  };
  const openpdf = () => {
    setIsPdfPop(true);
  };
  const handleSendDeviceDetailsLSQ = async (examId, eventId, retryCount = 0) => {
    try {
      const payload = createDeviceDetailsPayloadLSQ();
      await sendDeviceDetails({ examId, eventId, payload }).unwrap();
    } catch (err) {
      console.log(`Attempt ${retryCount + 1} failed: ${err.message}`);

      if (retryCount < 3) {
        setTimeout(() => {
          handleSendDeviceDetailsLSQ(examId, eventId, retryCount + 1);
        }, 1000);
      } else {
        console.log("Max retry attempts reached. Could not fetch the exam report.");
      }
    }
  };
  const handleFetchExamReport = async (examId, retryCount = 0) => {
    try {
      const data = await fetchExamReport(examId);
      setReportData(data.data);
      setProgressordata(data.data.section_wise_marks);
      setReportLoader(false);
      handleSendDeviceDetailsLSQ(examId, examdetailes.event_id);
    } catch (error) {
      console.log(`Attempt ${retryCount + 1} failed: ${error.message}`);

      if (retryCount < 3) {
        setTimeout(() => {
          handleFetchExamReport(examId, retryCount + 1);
        }, 1000);
      } else {
        console.log("Max retry attempts reached. Could not fetch the exam report.");
      }
    }
  };

  const handleFetchExamdetials = async (examId) => {
    try {
      const data = await fetchExamDetails(examId);
      setExamDetails(data.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const handelPaymetflow = async (initialTimeLeft, currentExam, examdetailes) => {
    const res = await handlePaymentFlow(initialTimeLeft, currentExam, examdetailes);
    setDiscount(res);
  };
  const handleFetchExamStatus = async (examId, currentExam) => {
    try {
      let submittedAt;

      if (examdetailes.submitedAt) {
        const utcDate = parseISO(examdetailes.submitedAt);
        submittedAt = new Date(utcDate.getTime() + 5.5 * 60 * 60 * 1000 - 48 * 1000);
      } else {
        submittedAt = parseISO((await fetchExamStatus(examId)).data.submitted_at);
      }
      setFormetData(format(submittedAt, "dd MMMM yyyy"));

      const timeDifference = Math.floor((new Date() - submittedAt) / 1000);
      const initialTimeLeft = Math.max(7200 - timeDifference, 0);
      setTimeLeft(initialTimeLeft);
      if (isPaidReport) {
        handelPaymetflow(initialTimeLeft, currentExam, examdetailes);
      }
    } catch (error) {
      console.error("Error fetching exam status:", error.message);
    }
  };

  const handleUpdateExamStatus = async (examId, event_id) => {
    try {
      await updateStatus({ examId: examId, event_id: event_id });
    } catch (error) {
      console.log(error.message);
    }
  };
  const handelSendAdvanceReportLSQ = async () => {
    switch (currentExam) {
      case "score":
        setAdvanced(true);
        break;
      case "hotsolympiad":
        history.push("/olympaidreports");
        break;
      case "matholympiad":
        history.push("/olympaidreports");
        break;
      case "iemsat":
        history.push("/iemsatreports");
        break;
      default:
        console.warn("Unknown exam title:", examdetailes?.title);
        break;
    }
  };
  const handelAdvancedReport = async (examId, event) => {
    try {
      const data = await fetchAdvancereport({ examId: examId, event: event });
      if (data?.data?.advanced_report.length > 0) {
        DetailedReportWebEngage();
        setAdvanceReport(data?.data?.advanced_report[0]);
        handelSendAdvanceReportLSQ();
      } else {
        if (!isPaidReport) {
          setCondition("NoPymmentalmost");
        } else {
          setCondition("Notyet");
        }
      }
    } catch (error) {
      console.error("Error fetching report:", error);
      return null;
    }
  };

  useEffect(() => {
    setReportLoader(true);
    handleUpdateExamStatus(examId, examdetailes.event_id);
    handleFetchExamdetials(examId);
    handleFetchExamReport(examId);
    handleFetchExamStatus(examId, currentExam);
  }, []);
  useEffect(() => {
    if (isPaidReport) {
      if (timeLeft > 0 && discount === true) {
        setCondition("almost");
      } else if (timeLeft === 0 && discount === true) {
        setCondition("Notyet");
      } else if (timeLeft === 0 && discount === false) {
        setCondition("timer");
      }
    } else {
      if (timeLeft > 0) {
        setCondition("NoPymmenttimer");
      } else if (timeLeft === 0) {
        handelAdvancedReport(examId, currentExam);
      }
    }

    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [timeLeft, discount, isPaidReport]);

  useEffect(() => {
    if (timeLeft === 0 && !hasFetched && discount === true) {
      setCondition("Notyet");
      handelAdvancedReport(examId, currentExam);
      setHasFetched(true);
    }
  }, [timeLeft, examId, currentExam, discount]);

  const DetailedReportWebEngage = async () => {
    try {
      await webEngage({
        crn_id: `${examdetailes?.crn_id}`,
        event_name: "Detailed_Report_Generated_Inmobius",
        event_data: [
          { attribute: "test_name", value: `${examdetailes?.StudentExamType + " " + examdetailes?.level}` },
          { attribute: "test_type", value: `${examdetailes?.title}` },
          { attribute: "event_id", value: `${examdetailes?.event_id}` },
          {
            attribute: "CAR_report_basic_link",
            value: `${CONFIG_VARS.report_link}${currentExam}-report/${examdetailes?.crn_id}/${examdetailes?.event_id}`
          },
          { attribute: "Platform", value: "InMobius" },
          { attribute: "page_url", value: `/reports/${examdetailes?.crn_id}/${examId}` }
        ]
      });
    } catch (error) {
      console.error("Error pushing WebEngage event:", error);
    }
  };

  const access_token = useSelector(getToken);
  return (
    <>
      {!reportLoader ? (
        <div className="Rep_main_cont">
          <BasicReport
            reportData={reportData}
            access_token={access_token}
            progressordata={progressordata}
            examDetails={examDetails}
            formetDate={formetDate}
            examdetailes={examdetailes}
          />
          <div>
            {!advanced ? (
              <Timer
                condition={condition}
                // handelSendAdvanceReportLSQ={handelSendAdvanceReportLSQ}
                timeLeft={timeLeft}
                openPopup={openPopup}
                openpdf={openpdf}
                examType={currentExam}
                discount={discount}
                examId={examId}
              />
            ) : (
              <AdvanceReport advanceReport={advanceReport} />
            )}
          </div>
          <PackageReviwPopup
            setIsPopupOpen={setIsPopupOpen}
            isPopupOpen={isPopupOpen}
            openPopup={openPopup}
            examType={currentExam}
            discount={discount}
          />
          <SampleReportPopup
            setIsPopupOpen={setIsPdfPop}
            isPopupOpen={isPdfPop}
            openPopup={openpdf}
            examType={currentExam}
          />
        </div>
      ) : (
        <div className="ReportLoaderCont">
          <img className="Reploader" src={loader} />
        </div>
      )}
    </>
  );
};
