import React, { useState } from "react";
import NoEventPNG from "../../assets/images/onboarding/Noevent.png";
import { EventCardShort } from "./EventCardShort";
import loader from "../../assets/gif/newLoader.gif";
import { getRegistrationPayload, getMdm } from "../../store/auth/selectors";
import { useSelector } from "react-redux";

export const StudentDashBoard = ({ thisWeekEvents, isLoading, setIsLoading, fetchEvents }) => {
  const [noTest, setNoTest] = useState(false);
  const [hasRetried, setHasRetried] = useState(false);
  const sortedEvents = thisWeekEvents?.slice().sort((a, b) => {
    const startTimeA = new Date(a.startRecur).getTime();
    const startTimeB = new Date(b.startRecur).getTime();
    return startTimeB - startTimeA;
  });
  const registrationPayload = useSelector(getRegistrationPayload);
  const registerdExam = registrationPayload?.student_response[0]?.answer;
  const mdm = useSelector(getMdm);
  const grade = mdm.grade_id;

  const handleTryAgain = () => {
    setIsLoading(true);
    fetchEvents();
  };

  // Determine if we have any events to render
  const eventsToRender = sortedEvents?.filter((value) => {
    const { examType, StudentExamType, event_code } = value;
    const isStudentExamTypeMatched = examType?.includes(StudentExamType);
    const isRegisteredExamMatched = examType?.includes(registerdExam);
    const isGradeInRange = grade > 0 && grade < 9;
    const isMathOlympiad = event_code === "Math Olympiad";
    const isHotsOlympiad = event_code === "Hots Olympiad";

    return isStudentExamTypeMatched || isRegisteredExamMatched || isGradeInRange || isMathOlympiad || isHotsOlympiad;
  });

  // Update state if no tests are found
  // Helper delay function
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  React.useEffect(() => {
    const fetchWithDelay = async () => {
      if (!isLoading && (!eventsToRender || eventsToRender.length === 0)) {
        console.log("No tests found");
        setNoTest(true);

        if (!hasRetried) {
          setHasRetried(true);

          // Delay for 2 seconds
          await delay(2000);

          console.log("Fetching events after delay");
          setIsLoading(true);
          fetchEvents();
        }
      } else {
        setNoTest(false);
      }
    };

    fetchWithDelay();
  }, [isLoading, eventsToRender, fetchEvents, setIsLoading, hasRetried]);

  return (
    <div className="row mt-4">
      {isLoading ? (
        <div className="loaderconstiner mt-5">
          <img className="loader" src={loader} />
          <p className="loader-para">Loading Please Wait...</p>
        </div>
      ) : noTest ? (
        <div className="no_testCard">
          <img className="normalIcon mb-3" src={NoEventPNG} alt="discovery" />
          <p>No Tests Scheduled for now</p>
          <p className="board__top--heading align-self-center" onClick={handleTryAgain}>
            Try Again
          </p>
        </div>
      ) : (
        <div className="Scheduleevent-cont">
          <div className="planer_exam-cards-cont">
            {eventsToRender.map((value, index) => (
              <EventCardShort key={index} data={value} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
