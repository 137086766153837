import React, { useState, useEffect } from "react";
import blueedit from "../../../assets/svg/emsat/Edit Squareblue.svg";
import yelloedit from "../../../assets/svg/emsat/Edit Square.svg";
import { EMsatToolTip } from "./EMsatTooltip";
import i from "../../../assets/svg/emsat/i.svg";

const SubjectDetaiedReport = ({ SubSection, chapterData }) => {
  const [selectedButton, setSelectedButton] = useState("");

  useEffect(() => {
    if (chapterData && chapterData.length > 0) {
      setSelectedButton(chapterData[0]?.SECTION || "");
    }
  }, [chapterData]);

  const getAccuracyBar = (accuracy) => `${accuracy}%`;

  const handleButtonClick = (value) => {
    setSelectedButton(value);
  };

  const getPerformanceColor = (performance) => {
    switch (performance) {
      case "Keep it up":
        return "#00966D";
      case "Needs Improvement":
        return "#1F1F1F";
      default:
        return "#000"; // Default color if none of the above
    }
  };

  const getImportanceColor = (importance) => {
    switch (importance) {
      case "High":
        return "#A40038";
      case "Medium":
        return "#946200";
      case "Low":
        return "#23628D";
      default:
        return "#000"; // Default color if none of the above
    }
  };

  // Filter chapterData based on the selected tab
  const selectedChapterData = chapterData?.find((section) => section?.SECTION === selectedButton);

  // Filter SubSection based on the selected section
  const selectedSubjectData = SubSection?.filter((section) => section?.SECTION === selectedButton) || [];

  return (
    <div className="EM-SDR-cnt">
      {/* Subject Tabs */}
      <div className="EM-SDR-btn-cnt">
        {chapterData?.map((value, key) => (
          <button
            key={key}
            onClick={() => handleButtonClick(value.SECTION)}
            className={`tab_subject ${selectedButton === value.SECTION ? "selected_tab" : ""}`}
          >
            {value.SECTION}
          </button>
        ))}
      </div>

      {/* Chapters Report Section */}
      <div className="EMRep_ChapterW_cont ">
        <div className="EMRep_Scroll ">
          <div className="EMRep_SubW_Headercont">
            <p className="EMRep_SubW_HeaderCapter">Chapters</p>
            <p className="EMRep_SubW_Headerimp">Chapter Importance</p>
            <p className="EMRep_SubW_Headertotal">Total Questions</p>
            <p className="EMRep_SubW_HeaderAcur">
              Accuracy of correct answers{" "}
              <span>
                <EMsatToolTip>
                  <img className="EMRe_info" style={{ marginBottom: "0px" }} src={i} alt="i" />
                </EMsatToolTip>
              </span>
            </p>
            <p className="EMRep_SubW_Headermarks">Marks Achieved</p>
            <p className="EMRep_SubW_HeaderPerf">Performance</p>
          </div>

          {selectedChapterData?.chapters?.map((chapter, key) => (
            <div key={key} className="EMRep_SubW_cardCont">
              <p className="EMRep_SubW_BodyCapter">{chapter?.CHAPTER}</p>
              <p className="EMRep_SubW_Bodyimp" style={{ color: getImportanceColor(chapter?.CHAPTER_IMPORTANCE) }}>
                {chapter?.CHAPTER_IMPORTANCE ? chapter?.CHAPTER_IMPORTANCE : "-"}
              </p>
              <p className="EMRep_SubW_Bodytotal">{chapter?.TOTAL_QUESTIONS_ASKED}</p>
              {chapter.ACCURACY ? (
                <p className="EMRep_SubW_BodyAcur">
                  <div className="EMRep_SubW_BodyAcurBar">
                    <div
                      style={{
                        backgroundColor: "#3C8DCB",
                        width: getAccuracyBar(chapter.percentage)
                      }}
                    ></div>
                  </div>
                  {Math.round(chapter.ACCURACY)}%
                </p>
              ) : (
                <p className="EMRep_SubW_BodyAcur">0%</p>
              )}
              <p className="EMRep_SubW_Bodymarks">{chapter?.MARKS_SCORED}</p>
              <p className="EMRep_SubW_BodyPerf" style={{ color: getPerformanceColor(chapter?.PERFORMANCE_METRICS) }}>
                {chapter?.PERFORMANCE_METRICS}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Types of Questions Report Section */}
      <div className="EMRep_ChapterW_cont ">
        <div className="EMRep_SubScroll ">
          <div className="EMRep_SubW_Headercont">
            <p className="EMRep_SubW_HeaderType">Types of Questions</p>
            <p className="EMRep_SubW_Headertotal">Total Questions</p>
            <p className="EMRep_SubW_HeaderAcur">
              Accuracy of correct answers{" "}
              <span>
                <EMsatToolTip>
                  <img className="EMRe_info" style={{ marginBottom: "0px" }} src={i} alt="i" />
                </EMsatToolTip>
              </span>
            </p>
            <p className="EMRep_SubW_Headermarks">Marks Achieved</p>
            <p className="EMRep_SubW_HeaderPerf">Performance</p>
          </div>
          {selectedSubjectData?.map((chapter, key) => (
            <div key={key} className="EMRep_SubW_cardCont">
              <p className="EMRep_SubW_BodyType">{chapter?.OBJECTIVE_TYPE}</p>
              <p className="EMRep_SubW_Bodytotal">{chapter?.TOTAL_QUESTIONS}</p>
              {chapter?.ACCURACY ? (
                <p className="EMRep_SubW_BodyAcur">
                  <div className="EMRep_SubW_BodyAcurBar">
                    <div
                      style={{
                        backgroundColor: "#3C8DCB",
                        width: getAccuracyBar(chapter?.ACCURACY)
                      }}
                    ></div>
                  </div>
                  {Math.round(chapter?.ACCURACY)}%
                </p>
              ) : (
                <p className="EMRep_SubW_BodyAcur">0%</p>
              )}
              <p className="EMRep_SubW_Bodymarks">{chapter?.CORRECT}</p>
              <p className="EMRep_SubW_BodyPerf" style={{ color: getPerformanceColor(chapter?.PERFORMANCE_METRICS) }}>
                {chapter?.PERFORMANCE_METRICS ? chapter?.PERFORMANCE_METRICS : "-"}
              </p>
            </div>
          ))}
        </div>
        <div style={{ display: "none" }} className="EMRep_Area_cont">
          <div className="EMRep_Area_cont1">
            <div>
              <h6> Areas of Strength</h6>
            </div>
            <div className="EMRep_Area_content">
              <img src={blueedit} />
              <p>
                Your performance on Chapter <span>Electric Charges and Fields </span>is the best.
              </p>
            </div>
            <div className="EMRep_Area_content">
              <img src={blueedit} />
              <p>
                Your performance on Formula-based type of questions is the best. You demonstrated skills in applying
                formulas in question across various chapters.
              </p>
            </div>
          </div>
          <div className="EMRep-verticalLine"></div>
          <div className="EMRep_Area_cont1">
            <div>
              <h6> Areas of Strength</h6>
            </div>
            <div className="EMRep_Area_content">
              <img src={yelloedit} />
              <p>
                Your performance on <span>Chapter Electric Charges and Fields </span>is the best.
              </p>
            </div>
            <div className="EMRep_Area_content">
              <img src={blueedit} />
              <p>
                Your performance on Formula-based type of questions is the best. You demonstrated skills in applying
                formulas in question across various chapters.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubjectDetaiedReport;
