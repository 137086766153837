import UAParser from "ua-parser-js";

const getDeviceDetails = () => {
  const parser = new UAParser();
  const result = parser.getResult();

  const manufacturer = result.device.vendor || "Unknown";
  const model = result.device.model || "Unknown";
  const brand = result.device.vendor || "Unknown";
  const os_name = `${result.os.name} ${result.os.version}`;
  const device_category = result.device.type || "web";

  return {
    manufacturer: manufacturer,
    model: model,
    brand: brand,
    os_name: os_name,
    device_category: device_category
  };
};

const createDeviceDetailsPayloadLSQ = () => {
  const deviceDetails = getDeviceDetails();

  const payload = {
    device_attributes: [
      {
        name: "mx_CustomObject_1",
        value: deviceDetails.manufacturer
      },
      {
        name: "mx_CustomObject_2",
        value: deviceDetails.model
      },
      {
        name: "mx_CustomObject_3",
        value: deviceDetails.brand
      },
      {
        name: "mx_CustomObject_4",
        value: deviceDetails.os_name
      },
      {
        name: "mx_CustomObject_5",
        value: deviceDetails.device_category
      }
    ]
  };

  return payload;
};

export { createDeviceDetailsPayloadLSQ, getDeviceDetails };
