import React, { useEffect } from "react";
import { StudentSignUpForm } from "./auth-widget/student-auth-widget/forms/StudentSignUpForm";
import { ROUTES } from "../constants/routes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
export const StudentSignUpWidget = ({ toggleTAndC, togglePAndP }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isHomePage = history.location.pathname === ROUTES.HOME;
  const content = t("home_page.p1_content");

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the route changes
  }, []);

  return (
    <>
      <header className="frontheader__banner">
        <div className="white__layerbg"></div>
        <div className="banner__contentbox no_auth">
          <div className="container">
            <div className="row">
              <div className={`col-lg-${isHomePage ? "12" : "7"}`}>
                <h1 className="banner__contentbox--title">
                  <span className="text--secondary--grad">{t("home_page.p1_heading")}</span>
                </h1>
                <p className="banner__contentbox--text mt-2 pt-1">{content}</p>
              </div>
              <div className="col-md-6 col-lg-5 position-relative">
                <div className="membership__card membership__card_mob mx-auto ms-md-auto">
                  <div className="tab-content">
                    <h3 className="tab-heading">Create Account </h3>
                    <StudentSignUpForm toggleTAndC={toggleTAndC} togglePAndP={togglePAndP} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
