import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "./auth.api";

export const USER_TYPES = Object.freeze({
  SCHOOL: "school",
  STUDENT: "student",
  TEACHER: "teacher"
});

const initialState = {
  user: null,
  userType: null,
  tokens: null,
  mdm: null,
  selectedEventsDetails: null,
  registrationPayload: null,
  paymentsDetails: null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userLogout(state) {
      state.user = initialState.user;
      state.userType = initialState.userType;
      state.tokens = initialState.tokens;
      state.registrationPayload = initialState.registrationPayload;
      state.selectedEventsDetails = initialState.selectedEventsDetails;
      state.paymentsDetails = initialState.paymentsDetails;
    },
    setUserActive(state) {
      state.user.is_active = true;
    },
    setSelectedEventsDetails(state, action) {
      // Add this reducer
      state.selectedEventsDetails = action.payload;
    },
    setPaymentDetails(state, action) {
      state.paymentsDetails = action.payload;
    },
    clearSelectedEventsDetails(state) {
      // Add this reducer
      state.selectedEventsDetails = null;
    },
    setRegistrationPayload(state, action) {
      state.registrationPayload = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.signupSchoolFinish.matchFulfilled, setPlanInfo)
      .addMatcher(authApi.endpoints.signupSchool.matchFulfilled, setSchoolInfo)
      .addMatcher(authApi.endpoints.loginSchool.matchFulfilled, setSchoolInfo)
      .addMatcher(authApi.endpoints.studentLoginOTP.matchFulfilled, setStudentInfo)
      .addMatcher(authApi.endpoints.studentSignUp.matchFulfilled, setStudentInfo)
      .addMatcher(authApi.endpoints.studentLoginPassword.matchFulfilled, setStudentInfo)
      .addMatcher(authApi.endpoints.studentLoginAdmissionID.matchFulfilled, setStudentInfo);
  }
});

export const {
  userLogout,
  setUserActive,
  setSelectedEventsDetails,
  clearSelectedEventsDetails,
  setRegistrationPayload,
  setPaymentDetails
} = authSlice.actions;
export default authSlice.reducer;

const setPlanInfo = (state, { payload }) => {
  if (payload) {
    state.planInfo = payload;
  }
};
const setSchoolInfo = (state, { payload }) => {
  if (payload) {
    state.userType = USER_TYPES.SCHOOL;

    state.user = payload.user;
    state.tokens = payload.token;
  }
};
const setStudentInfo = (state, { payload }) => {
  if (payload) {
    const studentsData = payload?.details || payload;
    state.userType = USER_TYPES.STUDENT;
    state.user = studentsData.user;
    state.tokens = studentsData.token;
    state.mdm = studentsData.mdm;

    const userCrn_id = state.user ? state.user.crn_id : "";
    if (userCrn_id && userCrn_id.length > 4) {
      const gaEventObj = {
        event: "userData_InMobius",
        user_id: userCrn_id
      };
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(gaEventObj);
    }
  }
};
