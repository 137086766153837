import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import back from "../assets/svg/reportspage/leftarow.svg";
import leftArrow from "../assets/svg/reportspage/LeftArrow.svg";
import { ROUTES } from "../constants/routes";
import certific from "../assets/svg/reportspage/certificateicon.svg";
import { LineChart } from "./components/EMsat/LineChart";
import { PaiChart } from "./components/EMsat/PaiChart";
import ColumnProgressBar from "./components/EMsat/EMsatTowerChart";
import TimeProgressors from "./components/EMsat/TimeProgressors";
import EMsatTimeManagement from "./components/EMsat/EMsatTimeManagement";
import Subjectwise from "./components/EMsat/Subjectwise";
import SubjectDetaiedReport from "./components/EMsat/SubjectDetaiedReport";
import { useSelector } from "react-redux";
import { getSelectedEventsDetails, getToken } from "../store/auth/selectors";
import { useAdvanceReportMutation, useExamStatusMutation, useWebEngageMutation } from "../store/ApiMutaion";
import { format, parseISO } from "date-fns";
import { CONFIG_VARS } from "../constants/configuration";

export const EmsatReports = () => {
  const history = useHistory();
  const [formetDate, setFormetData] = useState();
  const [reportData, setReportData] = useState(null);
  const examdetailes = useSelector(getSelectedEventsDetails);
  const [fetchAdvancereport] = useAdvanceReportMutation();
  const [fetchExamStatus] = useExamStatusMutation();
  const [webEngage] = useWebEngageMutation();
  const examId = examdetailes?.exam_id;
  const currentExam = examdetailes?.title?.toLowerCase().replace(/[\s\W]+/g, "");
  const handelAdvancedReport = async (examId, event) => {
    try {
      const response = await fetchAdvancereport({ examId: examId, event: event });
      setReportData(response?.data?.advanced_report[0]);
    } catch (error) {
      console.error("Error fetching report:", error);
      return null;
    }
  };
  const CertificateWebEngage = async () => {
    try {
      await webEngage({
        crn_id: `${examdetailes?.crn_id}`,
        event_name: "Download_Certificate_Inmobius",
        event_data: [
          { attribute: "test_name", value: `${examdetailes?.StudentExamType + " " + examdetailes?.level}` },
          { attribute: "test_type", value: `${examdetailes?.title}` },
          { attribute: "event_id", value: `${examdetailes?.event_id}` },
          { attribute: "Platform", value: "InMobius" },
          { attribute: "page_url", value: `/reports/${examdetailes?.crn_id}/${examId}` }
        ]
      });
    } catch (error) {
      console.error("Error pushing WebEngage event:", error);
    }
  };

  const ViewSollutionWebEngage = async () => {
    try {
      await webEngage({
        crn_id: `${examdetailes?.crn_id}`,
        event_name: "View_Solution_Inmobius",
        event_data: [
          { attribute: "test_name", value: `${examdetailes?.title + " " + examdetailes?.level}` },
          { attribute: "test_type", value: `${examdetailes?.title}` },
          { attribute: "event_id", value: `${examdetailes?.event_id}` },
          { attribute: "Platform", value: "InMobius" },
          { attribute: "page_url", value: `/reports/${examdetailes?.crn_id}/${examId}` }
        ]
      });
    } catch (error) {
      console.error("Error pushing WebEngage event:", error);
    }
  };
  const handleBackButtonClick = () => {
    history.push(ROUTES.DASHBOARD);
  };
  const access_token = useSelector(getToken);
  const handleViewSolutions = async () => {
    await ViewSollutionWebEngage();
    const baseUrl = `${CONFIG_VARS.scoreViewreportUrl}/test/viewsolutionspage?test_type=e34d1d14-6c33-48fd-900e-e8d3cb76da64`;
    const examId = examdetailes.exam_id;
    const qu_id = examdetailes.qu_id;
    const token = access_token.replace("Bearer ", "");
    const url = `${baseUrl}&question_paper_id=${qu_id}&token=${token}&exam_id=${examId}`;
    // window.open(url);
    window.open(url, "_self", "noreferrer");
  };

  const handleFetchExamStatus = async (examId) => {
    try {
      let submittedAt;
      if (examdetailes.submitedAt) {
        const utcDate = parseISO(examdetailes.submitedAt);
        submittedAt = new Date(utcDate.getTime() + 5.5 * 60 * 60 * 1000 - 48 * 1000);
      } else {
        submittedAt = parseISO((await fetchExamStatus(examId)).data.submitted_at);
      }
      setFormetData(format(submittedAt, "dd MMMM yyyy"));
    } catch (error) {
      console.error("Error fetching exam status:", error.message);
    }
  };
  useEffect(() => {
    handelAdvancedReport(examdetailes.exam_id, currentExam);
    handleFetchExamStatus(examdetailes.exam_id);
  }, []);
  const handleDownloadCertificet = async () => {
    const url = `${CONFIG_VARS.certficateUrl}/${examdetailes.crn_id}/${examdetailes.event_id}.pdf`;
    await CertificateWebEngage();
    window.open(url);
  };
  const getQuestionStatusData = (reportData, status) => {
    return reportData?.question_status_time?.find((item) => item.QUESTION_STATUS === status);
  };

  const correctData = getQuestionStatusData(reportData, "Correct");
  const incorrectData = getQuestionStatusData(reportData, "Incorrect");
  const unattemptedData = getQuestionStatusData(reportData, "Unattempted");

  return (
    <div className="Rep_main_cont">
      <div className="Rep_btn-cont">
        <button onClick={handleBackButtonClick} className="Rep_back-btn">
          <span>
            <img src={back} />
          </span>
          back
        </button>
        <div className="Rep_back-btn2">
          <div className="Rep_back-btn2In">
            {" "}
            <img onClick={handleBackButtonClick} src={leftArrow} /> <p>Report</p>
          </div>
        </div>
        <button onClick={handleViewSolutions} className="attempt-btn Rep_attempt-btn1">
          View Solutions
        </button>
      </div>
      <div>
        <h1 className="Rep_exam-title">
          I-EMSAT {examdetailes.level} | {formetDate}
        </h1>
        <div className="cetrificate-cont">
          <div className="Rep-text-cont">
            <img src={certific} alt="note" className="mock_img mock_imgweb" />
            <div className="Rep_mock-Cont">
              <h2 className="mock-text Rep_mock-text">Test Completed</h2>
              <p className="mock-para Rep_mock-para ">
                Share your achievement with friends and family! Celebrate and download your certificate now.
              </p>
            </div>
            <img src={certific} alt="note" className="mock_img mock_imgmob" />
          </div>
          <button onClick={handleDownloadCertificet} className="attempt-btn Rep_attempt-btn2">
            Download Certificate
          </button>
        </div>

        <h3 style={{ display: "none" }} className="Rep_sub-head">
          Summary
        </h3>
        <div style={{ display: "none" }} className="EMsatRep_score_card">
          <div className="EMsatRep_score_carditem">
            <p>.</p>
            <p> Your avg. time/question is close to the ideal avg. time/question of this paper.</p>
          </div>

          <div className="EMsatRep_score_carditem">
            <p>.</p>
            <p> You performed well in Physics. You need expert guidance in Mathematics.</p>
          </div>

          <div className="EMsatRep_score_carditem">
            <p>.</p> <p> Your Score is higher than the average Tier-1 College cutoff. Well done!</p>
          </div>
        </div>

        <h3 className="Rep_sub-head">Highlights</h3>

        <div className="EMRep_Sub_cont">
          <PaiChart
            totalMarks={reportData?.exam_details[0]?.MAX_MARKS_PER_EXAM}
            scoredMarks={reportData?.exam_details[0]?.CURRENT_MARKS}
            accuracy={Math.round(reportData?.exam_details[0]?.ACCURACY)}
          />
          <LineChart
            totalScore={reportData?.exam_details[0]?.MAX_MARKS_PER_EXAM}
            userScore={reportData?.exam_details[0]?.CURRENT_MARKS}
            cutoff={reportData?.exam_details[0]?.CUT_OFF}
          />
        </div>
        <h3 className="Rep_sub-head">Performance</h3>
        <div className="Rep_card-cont">
          <ColumnProgressBar
            totalQuestions={reportData?.exam_details[0]?.MAX_MARKS_PER_EXAM}
            correctAnswers={reportData?.exam_details[0]?.CORRECT}
            incorrectAnswers={reportData?.exam_details[0]?.INCORRECT}
            unanswered={reportData?.exam_details[0]?.UNATTEMPTED}
          />{" "}
          <TimeProgressors
            totaltime={reportData?.exam_details[0]?.TOTAL_EXAM_TIME}
            totalTimeSpend={reportData?.exam_details[0]?.TOTAL_TIME_TAKEN}
            correctAnswerstimeSpend={correctData?.TOTAL_TIME ?? 0}
            incorrectAnswerstimeSpend={incorrectData?.TOTAL_TIME ?? 0}
            unansweredtimeSend={unattemptedData?.TOTAL_TIME ?? 0}
          />
        </div>
        <h3 className="Rep_sub-head">Time Management</h3>
        <EMsatTimeManagement
          totalTime={reportData?.exam_details[0]?.TOTAL_EXAM_TIME}
          Avgtime={reportData?.exam_details[0]?.AVG_TIME_TAKEN}
          Idealtime={reportData?.exam_details[0]?.IDEAL_AVG_TIME_PER_QUESTION_FOR_EVERY_PAPER_IN_SECONDS}
          correctAnswers={correctData?.AVG_TIME ?? 0}
          incorrectAnswers={incorrectData?.AVG_TIME ?? 0}
          unanswered={unattemptedData?.AVG_TIME ?? 0}
        />
        <h3 className="Rep_sub-head">Subject-wise Performance</h3>
        <Subjectwise reportData={reportData?.sections} />
        <SubjectDetaiedReport SubSection={reportData?.sub_section_details} chapterData={reportData?.sections} />
      </div>
    </div>
  );
};
