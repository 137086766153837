import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { StudentOnboardingProgressBar } from "./ProgressBar";
import { StudentOnboardingTalkingBox } from "./TalkingBox";
import { QuestionsBlock } from "./QuestionsBlock";
import { QUESTIONS } from "../questions";
import { useStudentSubmitQuestionsMutation } from "../../../../store/auth/auth.api";
import { getMdm, getUserInfo } from "../../../../store/auth/selectors";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { StudentOnboardingPopUp } from "./PopUp";
import { setRegistrationPayload } from "../../../../store/auth/auth.slice";
export const StudentOnboardingQuestionnaire = ({ onFinish }) => {
  const mdm = useSelector(getMdm);
  const dispatch = useDispatch();
  const defProgress = {
    questions: QUESTIONS,
    currentQuestion: QUESTIONS[0],
    step: 0,
    answers: []
  };

  const getProgress = (total, current) => {
    return (current / total) * 100;
  };
  // const [tbAnimate] = useAutoAnimate();
  const autoAnimateResult = useAutoAnimate();
  const tbAnimate = Array.isArray(autoAnimateResult) ? autoAnimateResult[0] : autoAnimateResult;
  const [progress, setProgress] = useState(defProgress);
  const [showModal, setShowModal] = useState(false);
  const user = useSelector(getUserInfo);
  const [submitQuestions] = useStudentSubmitQuestionsMutation();
  const [apiError, setApiError] = useState(null);

  const nextStep = () => {
    setProgress((prevState) => {
      prevState.step = prevState.step + 1;
      prevState.currentQuestion = prevState.questions[prevState.step];
      return { ...prevState };
    });
  };

  const prevStep = (goBack = true) => {
    setProgress((prevState) => {
      if (prevState.step > 0) {
        prevState.step = goBack ? prevState.step - 1 : prevState.step;
        prevState.currentQuestion = prevState.questions[prevState.step];
        prevState.answers.pop();
      }
      return { ...prevState };
    });
  };
  const addAnswer = (question, question_id, answer) => {
    setProgress((prevState) => {
      if (mdm.grade_id >= 1 && mdm.grade_id <= 5 && prevState.answers.length === 0) {
        prevState.answers.unshift({
          question: "Q1",
          question_id: "1",
          answer: "Aptitude Test"
        });
      }
      if (mdm.grade_id >= 6 && mdm.grade_id <= 8 && prevState.answers.length === 0) {
        prevState.answers.unshift({
          question: "Q1",
          question_id: "1",
          answer: "Foundation Test"
        });
      }
      prevState.answers.push({ question, question_id, answer });
      return { ...prevState };
    });
  };

  const onPick = (question, question_id, answer) => {
    if (progress.answers.length < progress.questions.length) {
      addAnswer(question, question_id, answer);
    }
    if (progress.step + 1 >= progress.questions.length) {
      setShowModal(true);
    } else {
      nextStep();
    }
  };

  const onSubmitQuestions = async (submit) => {
    if (submit) {
      const payload = { crn_id: user?.crn_id, student_response: progress.answers };
      dispatch(setRegistrationPayload(payload));
      const result = await submitQuestions(payload);
      console.log("API Result:", result);
      if (result?.data?.details) {
        console.log("onfinish");
        onFinish();
        setShowModal(false);
      } else {
        setShowModal(true);
        setApiError("Your response was not captured,  please try again");
      }
    } else {
      prevStep(false);
    }
  };

  return (
    <section className="student__onboarding__widget justify-content-start d-block d-md-flex w-100">
      {progress.currentQuestion && (
        <div className="student__onboarding__progress">
          <StudentOnboardingProgressBar
            percentage={getProgress(progress.questions.length, progress.step + 1)}
            prevStep={prevStep}
          />
          <StudentOnboardingTalkingBox
            key={`2${progress?.step}`}
            animateRef={tbAnimate}
            question={progress.currentQuestion.question}
          />
          <QuestionsBlock
            key={`3${progress?.step}`}
            curQuestion={progress.currentQuestion}
            animateRef={tbAnimate}
            onPick={onPick}
          />
          <StudentOnboardingPopUp
            errormsg={apiError}
            show={showModal}
            setModal={setShowModal}
            onSelect={onSubmitQuestions}
            onFinish={onFinish}
            setApiError={setApiError}
          />
        </div>
      )}
    </section>
  );
};
